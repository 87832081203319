<template>
  <v-main>
    <div class="text-center">
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">mail</p>
        <v-text-field v-model="mail" single-line outlined dense></v-text-field>
      </v-col>
      <v-btn rounded class="maincolor-bg default_button"
             @click.stop="callApi()">メールアドレス変更</v-btn>
    </div>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      init: this.storageGet('*'),
      mail: null
    };
  },
  mounted () {
    this.mail = this.init.login_id
  },
  methods: {
    async callApi() {
      try {
        const res = await this.apiCallCustomerPF('/user/get', {'yamaya_id': this.init.device_uid});

        // アプリ会員情報登録API
        const user_req = {
          'type'      : 'EC',
          'mail'      : this.mail,
          'membership_status'  : res.user.membership_status,
        };
        await this.callPfApiFromEcMock('/user/update/' + this.init.device_uid, user_req);

        // サブメニュー表示
        this.requestToNativeToShowSubmenu()
      } catch(e) {
        console.log(e);
        this.loading = false
        this.callDialog('エラー', e.message, 3);
      }
    }
  }
};
</script>

<style scoped>
.fontsize11> >>label {
  font-size: 11px;
}

a {
  color: #333333;
}

</style>
